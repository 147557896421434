import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/CloseButton.css';

const CloseButton = () => {
    const navigate = useNavigate();

    // Handle the click event to navigate back to the main page
    const handleClose = () => {
        navigate('/');
    };

    let fill_color = "#ffffff";

    return (
        <div>
            <button className="close-button" onClick={handleClose}>
            <svg className='glitch' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.22656 18.57422">
                <path d="M16.36133,0H3.86475C1.73389,0,0,1.73389,0,3.86475v10.84424c0,2.13086,1.73389,3.86523,3.86475,3.86523h12.49658c2.13086,0,3.86523-1.73438,3.86523-3.86523V3.86475c0-2.13086-1.73438-3.86475-3.86523-3.86475ZM18.72656,3.86475v10.17548l-1.65289-1.57361c.24554-.50745.39508-1.06921.39508-1.66974V3.86475c0-.88049-.30774-1.68359-.8056-2.33423,1.16028.15015,2.06342,1.13385,2.06342,2.33423ZM13.60352,1.5c1.30371,0,2.36523,1.06104,2.36523,2.36475v6.93213c0,1.30371-1.06152,2.36426-2.36523,2.36426H5.08789c-1.30371,0-2.36475-1.06055-2.36475-2.36426V3.86475c0-1.30371,1.06104-2.36475,2.36475-2.36475h8.51562ZM2.04626,16.20203c-.33014-.40143-.53644-.9068-.54333-1.46411l.87677-1.19067c.18036.17755.3786.33606.59076.47577l-.92419,2.17902ZM3.04541,16.91919l1.02295-2.41187c.3269.09009.66437.15381,1.01953.15381h8.51562c.46948,0,.91412-.09662,1.33081-.25061l1.30621,2.6637H3.86475c-.28912,0-.56299-.05957-.81934-.15503ZM17.44354,16.79956l-1.46143-2.98016c.14038-.11072.27875-.22247.40253-.3512l2.18164,2.07697c-.20752.54474-.61005.98767-1.12274,1.25439Z" fill={fill_color}/>
                <path d="M6.68506,6.55957c.05469.06055.12646.09082.21484.09082s.15967-.03076.21338-.09229.08057-.16357.08057-.30615v-.91406l-2.65283-.00293c-.14209,0-.24365.02783-.3042.0835s-.09082.12842-.09082.21826.03027.1626.09082.21826.16211.0835.3042.0835h.09229v2.34961h-.09229c-.14014,0-.24121.02783-.30273.0835s-.09229.12744-.09229.21533c0,.08984.03027.1626.09082.21826s.16211.0835.3042.0835h2.78271v-.92578c0-.14453-.02734-.24756-.08203-.30908s-.12549-.09229-.21191-.09229c-.08838,0-.16016.03076-.21484.09229s-.08203.16455-.08203.30908v.3252h-1.5083v-.88184h.51904c0,.1582.01416.25977.04297.30469.05957.0957.14453.14355.25391.14355.104,0,.18652-.0459.24805-.1377.03076-.04492.04639-.13281.04639-.26367v-.69141c0-.14453-.02783-.24756-.08252-.30908s-.12549-.09229-.21191-.09229c-.10938,0-.19434.04785-.25391.14355-.02881.04492-.04297.14551-.04297.30176h-.51904v-.86719h1.37842v.31348c0,.14453.02734.24707.08203.30762Z" fill={fill_color}/>
                <path d="M8.31201,8.88574c.03467,0,.06836-.00684.10107-.02051.03223-.01367.06299-.0332.09229-.05859.12109.05859.25391.10303.39893.1333.14551.03027.30322.04541.47461.04541.42676,0,.77393-.10449,1.04102-.31348.18652-.14453.28027-.32422.28027-.53906,0-.1582-.05566-.30859-.16504-.45117s-.26514-.25-.46729-.32227c-.15381-.05469-.42676-.10938-.81885-.16406-.21338-.0293-.35059-.05957-.41211-.09082-.08105-.04297-.12109-.08691-.12109-.13184,0-.03906.02979-.0752.08936-.1084.13428-.07617.31592-.11426.54492-.11426.11523,0,.22559.01514.33154.04541.10547.03027.20654.07568.30273.13623.01562.07422.0498.13379.104.17871.05371.04492.11621.06738.1875.06738.10547,0,.18896-.04492.25049-.13477.03076-.04688.04639-.13574.04639-.2666v-.14648c0-.14453-.02686-.24756-.08105-.30908-.05371-.06152-.12109-.09229-.20166-.09229-.02686,0-.05615.00684-.08789.02051s-.06592.03418-.10254.06152c-.11523-.05469-.23584-.09521-.36182-.12158s-.25684-.03955-.39355-.03955c-.39209,0-.70947.09766-.95166.29297-.17871.14258-.26807.32129-.26807.53613,0,.20898.07959.38477.23926.52734.15967.14453.47705.24219.95166.29297.31885.03516.52979.07812.63135.12891.10205.05078.15283.11523.15283.19336,0,.04688-.03564.0918-.10645.13477-.1499.08594-.36035.12891-.63184.12891-.16504,0-.31104-.02002-.43799-.06006s-.23535-.09912-.32617-.17725c-.02686-.09766-.06396-.1665-.1123-.20654-.04785-.04004-.10742-.06006-.17871-.06006-.08838,0-.16016.03076-.21484.09229s-.08203.16455-.08203.30908v.20215c0,.14453.02734.24756.08203.30908s.12842.09229.2207.09229Z" fill={fill_color}/>
                <path d="M11.93555,8.69824c.25586.19336.61328.29004,1.07324.29004.51074,0,.89941-.08887,1.16504-.2666.17871-.11914.26758-.24512.26758-.37793,0-.08203-.0293-.15332-.08789-.21387s-.12793-.09082-.20898-.09082c-.06738,0-.14258.0376-.22461.11279-.08301.0752-.1709.12842-.2627.15967-.15625.05078-.35645.07617-.60254.07617-.375,0-.64355-.06787-.80469-.20361s-.24219-.3208-.24219-.55518c0-.25391.08398-.46387.25195-.62988.16895-.16602.3916-.24902.6709-.24902.24219,0,.44141.04688.59668.14062.08887.05273.14453.1333.16699.2417.02344.1084.05469.18018.0957.21533.05566.04688.12402.07031.2041.07031.08887,0,.16016-.03027.21484-.09082.05566-.06055.08301-.16309.08301-.30762v-.38672c0-.14258-.02734-.24463-.08301-.30615-.05469-.06152-.12402-.09229-.20898-.09229-.07422,0-.14746.03418-.21875.10254-.11914-.0625-.24902-.10938-.38965-.14062-.13965-.03125-.29199-.04688-.45508-.04688-.45215,0-.81836.14014-1.09863.42041-.28125.28027-.4209.63428-.4209,1.06201,0,.45117.17285.80664.51855,1.06641Z" fill={fill_color}/>
                </svg>
            </button>
            <div className="close-button-margin"></div>
        </div>
    );
};

export default CloseButton;
