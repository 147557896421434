import React from 'react';


const Award = ({ award }) => {
    let icon_color = '#fff';
    switch (award) {
        case 1:
            return <div className='awards glitch_no_blur'>
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5 52.5V47.5H27.5V39.75C25.4583 39.2917 23.6354 38.4271 22.0312 37.1562C20.4271 35.8854 19.25 34.2917 18.5 32.375C15.375 32 12.7604 30.6354 10.6562 28.2812C8.55208 25.9271 7.5 23.1667 7.5 20V17.5C7.5 16.125 7.98958 14.9479 8.96875 13.9688C9.94792 12.9896 11.125 12.5 12.5 12.5H17.5V7.5H42.5V12.5H47.5C48.875 12.5 50.0521 12.9896 51.0312 13.9688C52.0104 14.9479 52.5 16.125 52.5 17.5V20C52.5 23.1667 51.4479 25.9271 49.3438 28.2812C47.2396 30.6354 44.625 32 41.5 32.375C40.75 34.2917 39.5729 35.8854 37.9688 37.1562C36.3646 38.4271 34.5417 39.2917 32.5 39.75V47.5H42.5V52.5H17.5ZM17.5 27V17.5H12.5V20C12.5 21.5833 12.9583 23.0104 13.875 24.2812C14.7917 25.5521 16 26.4583 17.5 27ZM30 35C32.0833 35 33.8542 34.2708 35.3125 32.8125C36.7708 31.3542 37.5 29.5833 37.5 27.5V12.5H22.5V27.5C22.5 29.5833 23.2292 31.3542 24.6875 32.8125C26.1458 34.2708 27.9167 35 30 35ZM42.5 27C44 26.4583 45.2083 25.5521 46.125 24.2812C47.0417 23.0104 47.5 21.5833 47.5 20V17.5H42.5V27Z" fill={icon_color}/>
                    <path d="M29.656 29V17.8H31.464V29H29.656ZM27.416 19.448V17.8H31.304V19.448H27.416Z" fill={icon_color}/>
                </svg>
            </div>;
        case 2:
            return <div className='awards glitch_no_blur'>
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M44.3046 30.1325C43.8079 28.6424 43.0132 27.2517 42.0199 25.9603C41.8212 25.6623 41.6225 25.3642 41.3245 25.1656C41.3245 25.1656 41.3245 25.1656 41.1258 24.9669C40.9272 24.7682 40.7285 24.5695 40.5298 24.3709C39.6358 23.4768 38.7417 22.6821 37.7483 22.0861C37.1523 21.6887 36.457 21.3907 35.8609 21.0927C35.6623 21.0927 35.5629 21.0927 35.3642 20.894C35.1656 20.894 34.8675 20.6954 34.6689 20.596C34.5695 20.596 34.4702 20.596 34.3709 20.596C32.9801 20.1987 31.5894 20 30.0993 20H29.702C28.4106 20 27.1192 20.298 25.8278 20.596C24.6358 20.9934 23.4437 21.4901 22.351 22.0861C21.3576 22.6821 20.3642 23.3775 19.5695 24.2715C19.3709 24.4702 19.1722 24.6689 18.9735 24.9669L18.7748 25.1656C18.5762 25.4636 18.2781 25.6623 18.0795 25.9603C17.0861 27.2517 16.3907 28.6424 15.7947 30.1325C15.298 31.6225 15 33.3113 15 35C15 36.6887 15.298 38.3775 15.7947 39.8676C16.2914 41.3576 17.0861 42.7483 18.0795 44.0397C19.0728 45.4305 20.3642 46.5232 21.755 47.5166C22.947 48.3113 24.2384 48.9073 25.6291 49.3046C27.0199 49.702 28.5099 50 30 50C31.4901 50 32.9801 49.8013 34.3709 49.3046C35.7616 48.9073 37.053 48.2119 38.245 47.5166C38.543 47.3179 38.9404 47.0199 39.2384 46.8212C40.2318 46.0265 41.1258 45.1325 41.9205 44.1391C42.9139 42.8477 43.6093 41.457 44.2053 39.9669C44.702 38.4768 45 36.7881 45 35.0993C45 33.4106 44.702 31.7219 44.2053 30.2318L44.3046 30.1325ZM38.4437 43.3444C36.1589 45.6291 33.3775 46.8212 30 46.8212C26.6225 46.8212 23.9404 45.6291 21.6556 43.3444C19.3709 41.0596 18.1788 38.2781 18.1788 34.9007C18.1788 31.5232 19.3709 28.8411 21.6556 26.5563C23.9404 24.2715 26.7219 23.0795 30.0993 23.0795C33.4768 23.0795 36.1589 24.2715 38.4437 26.5563C40.7285 28.8411 41.9205 31.6225 41.9205 35C41.9205 38.3775 40.7285 41.0596 38.4437 43.3444Z" fill={icon_color}/>
                    <path d="M21.5 25.0857C22 24.6286 22.4 24.2629 22.9 23.9886L16.7 12.7429H24.1L29.1 21.8857C29.4 21.8857 29.7 21.8857 30 21.8857C30.9 21.8857 31.8 21.8857 32.6 22.16L31.6 20.3314L35.7 12.7429H43.1L36.9 23.8971C37.4 24.2629 37.9 24.6286 38.4 25.0857C38.7 25.36 39 25.7257 39.3 26C39.6 25.5429 39.8 25.1771 40 24.72L48 10H34L30 17.3143L26 10H12C14.9 15.3029 17.8 20.6057 20.7 25.9086C21 25.6343 21.2 25.36 21.5 25.0857Z" fill={icon_color}/>
                    <path d="M26.6199 39.912L30.7479 35.528C31.0892 35.1653 31.3665 34.84 31.5799 34.552C31.7932 34.264 31.9479 33.992 32.0439 33.736C32.1399 33.48 32.1879 33.2187 32.1879 32.952C32.1879 32.4507 32.0225 32.0613 31.6919 31.784C31.3719 31.496 30.9612 31.352 30.4599 31.352C29.9265 31.352 29.4572 31.4853 29.0519 31.752C28.6465 32.0187 28.2839 32.4347 27.9639 33L26.6839 31.944C27.1212 31.1867 27.6599 30.616 28.2999 30.232C28.9399 29.8373 29.6812 29.64 30.5239 29.64C31.2172 29.64 31.8252 29.7787 32.3479 30.056C32.8705 30.3333 33.2759 30.7227 33.5639 31.224C33.8519 31.7147 33.9959 32.2853 33.9959 32.936C33.9959 33.4053 33.9372 33.8267 33.8199 34.2C33.7132 34.5627 33.5265 34.9307 33.2599 35.304C32.9932 35.6667 32.6252 36.0933 32.1559 36.584L28.9879 39.864L26.6199 39.912ZM26.6199 41V39.912L28.2039 39.352H34.2199V41H26.6199Z" fill={icon_color}/>
                </svg>
            </div>;
        case 3:
            return <div className='awards glitch_no_blur'>
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M44.3046 30.1325C43.8079 28.6424 43.0132 27.2517 42.0199 25.9603C41.8212 25.6623 41.6225 25.3642 41.3245 25.1656C41.3245 25.1656 41.3245 25.1656 41.1258 24.9669C40.9272 24.7682 40.7285 24.5695 40.5298 24.3709C39.6358 23.4768 38.7417 22.6821 37.7483 22.0861C37.1523 21.6887 36.457 21.3907 35.8609 21.0927C35.6623 21.0927 35.5629 21.0927 35.3642 20.894C35.1656 20.894 34.8675 20.6954 34.6689 20.596C34.5695 20.596 34.4702 20.596 34.3709 20.596C32.9801 20.1987 31.5894 20 30.0993 20H29.702C28.4106 20 27.1192 20.298 25.8278 20.596C24.6358 20.9934 23.4437 21.4901 22.351 22.0861C21.3576 22.6821 20.3642 23.3775 19.5695 24.2715C19.3709 24.4702 19.1722 24.6689 18.9735 24.9669L18.7748 25.1656C18.5762 25.4636 18.2781 25.6623 18.0795 25.9603C17.0861 27.2517 16.3907 28.6424 15.7947 30.1325C15.298 31.6225 15 33.3113 15 35C15 36.6887 15.298 38.3775 15.7947 39.8676C16.2914 41.3576 17.0861 42.7483 18.0795 44.0397C19.0728 45.4305 20.3642 46.5232 21.755 47.5166C22.947 48.3113 24.2384 48.9073 25.6291 49.3046C27.0199 49.702 28.5099 50 30 50C31.4901 50 32.9801 49.8013 34.3709 49.3046C35.7616 48.9073 37.053 48.2119 38.245 47.5166C38.543 47.3179 38.9404 47.0199 39.2384 46.8212C40.2318 46.0265 41.1258 45.1325 41.9205 44.1391C42.9139 42.8477 43.6093 41.457 44.2053 39.9669C44.702 38.4768 45 36.7881 45 35.0993C45 33.4106 44.702 31.7219 44.2053 30.2318L44.3046 30.1325ZM38.4437 43.3444C36.1589 45.6291 33.3775 46.8212 30 46.8212C26.6225 46.8212 23.9404 45.6291 21.6556 43.3444C19.3709 41.0596 18.1788 38.2781 18.1788 34.9007C18.1788 31.5232 19.3709 28.8411 21.6556 26.5563C23.9404 24.2715 26.7219 23.0795 30.0993 23.0795C33.4768 23.0795 36.1589 24.2715 38.4437 26.5563C40.7285 28.8411 41.9205 31.6225 41.9205 35C41.9205 38.3775 40.7285 41.0596 38.4437 43.3444Z" fill={icon_color}/>
                    <path d="M21.5 25.0857C22 24.6286 22.4 24.2629 22.9 23.9886L16.7 12.7429H24.1L29.1 21.8857C29.4 21.8857 29.7 21.8857 30 21.8857C30.9 21.8857 31.8 21.8857 32.6 22.16L31.6 20.3314L35.7 12.7429H43.1L36.9 23.8971C37.4 24.2629 37.9 24.6286 38.4 25.0857C38.7 25.36 39 25.7257 39.3 26C39.6 25.5429 39.8 25.1771 40 24.72L48 10H34L30 17.3143L26 10H12C14.9 15.3029 17.8 20.6057 20.7 25.9086C21 25.6343 21.2 25.36 21.5 25.0857Z" fill={icon_color}/>
                    <path d="M30.1157 41.16C29.369 41.16 28.6917 41.0267 28.0837 40.76C27.4864 40.4827 26.9797 40.0827 26.5637 39.56L27.8117 38.312C28.0357 38.6533 28.3504 38.9253 28.7557 39.128C29.161 39.3307 29.6037 39.432 30.0837 39.432C30.5424 39.432 30.937 39.3467 31.2677 39.176C31.5984 39.0053 31.8544 38.7653 32.0357 38.456C32.2277 38.1467 32.3237 37.7893 32.3237 37.384C32.3237 36.9573 32.2277 36.5947 32.0357 36.296C31.8544 35.9973 31.5877 35.768 31.2357 35.608C30.8944 35.4373 30.4784 35.352 29.9877 35.352C29.7957 35.352 29.5984 35.368 29.3957 35.4C29.193 35.4213 29.0117 35.4587 28.8517 35.512L29.6677 34.456C29.8917 34.3707 30.1264 34.3013 30.3717 34.248C30.6277 34.184 30.873 34.152 31.1077 34.152C31.6837 34.152 32.201 34.2907 32.6597 34.568C33.129 34.8453 33.497 35.2347 33.7637 35.736C34.041 36.2267 34.1797 36.808 34.1797 37.48C34.1797 38.2053 34.009 38.8453 33.6677 39.4C33.3264 39.9547 32.8517 40.3867 32.2437 40.696C31.6357 41.0053 30.9264 41.16 30.1157 41.16ZM28.8517 35.512V34.424L31.8437 30.936L34.0517 30.888L30.9317 34.472L28.8517 35.512ZM27.1237 31.448V29.8H34.0517V30.888L32.4677 31.448H27.1237Z" fill={icon_color}/>
                </svg>
            </div>;
        case "special":
            return <div className='awards glitch_no_blur'>
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M45 35C45 33.3113 44.703 31.6225 44.2079 30.1325C43.7129 28.6424 42.9208 27.2517 41.9307 25.9603C41.7327 25.6623 41.5347 25.3642 41.2376 25.1656C41.2376 25.1656 41.1386 25.0662 41.0396 24.9669C40.8416 24.7682 40.6436 24.5695 40.4455 24.3709C39.5545 23.5762 38.6634 22.7815 37.6733 22.1854C37.0792 21.7881 36.3861 21.4901 35.7921 21.1921C35.5941 21.1921 35.495 21.0927 35.297 20.9934C35.099 20.9934 34.802 20.7947 34.604 20.6954C34.5049 20.6954 34.4059 20.6954 34.3069 20.596C32.9208 20.1987 31.5347 20 30.0495 20C28.5644 20 29.7525 20 29.6535 20C28.3663 20 27.0792 20.298 25.7921 20.596C24.604 20.9934 23.4158 21.4901 22.3267 22.0861C21.3366 22.6821 20.3465 23.3775 19.5545 24.2715C19.3564 24.4702 19.1584 24.6689 18.9604 24.9669C18.9604 24.9669 18.8614 25.0662 18.7624 25.1656C18.5644 25.4636 18.2673 25.6623 18.0693 25.9603C17.0792 27.2517 16.3861 28.6424 15.7921 30.1325C15.297 31.6225 15 33.3113 15 35C15 36.6887 15.297 38.3775 15.7921 39.8676C16.2871 41.3576 17.0792 42.7483 18.0693 44.0397C19.0594 45.4305 20.3465 46.5232 21.7327 47.5166C22.9208 48.3113 24.2079 48.9073 25.5941 49.3046C26.9802 49.702 28.4653 50 29.9505 50C31.4356 50 32.9208 49.8013 34.3069 49.3046C35.6931 48.9073 36.9802 48.2119 38.1683 47.5166C38.4653 47.3179 38.8614 47.0199 39.1584 46.8212C40.1485 46.0265 41.0396 45.1325 41.8317 44.1391C42.8218 42.8477 43.5148 41.457 44.1089 39.9669C44.604 38.4768 44.901 36.7881 44.901 35.0993L45 35ZM38.4653 43.3444C36.1881 45.6291 33.4158 46.8212 30.0495 46.8212C26.6832 46.8212 24.0099 45.6291 21.7327 43.3444C19.4554 41.0596 18.2673 38.2781 18.2673 34.9007C18.2673 31.5232 19.4554 28.8411 21.7327 26.5563C24.0099 24.2715 26.7822 23.0795 30.1485 23.0795C33.5149 23.0795 36.1881 24.2715 38.4653 26.5563C40.7426 28.8411 41.9307 31.6225 41.9307 35C41.9307 38.3775 40.7426 41.0596 38.4653 43.3444Z" fill={icon_color}/>
                    <path d="M24.5122 42L26.5976 35.9512L21 32.3415H27.8049L30 26L32.1951 32.3415H39L33.4024 35.9512L35.4878 42L30 38.1951L24.5122 42Z" fill={icon_color}/>
                    <path d="M21.6 25.0857C22.1 24.6286 22.6 24.2629 23.1 23.9886L16.9 12.7429H24.2L29.2 21.9771C29.5 21.9771 29.7 21.9771 30 21.9771C30.9 21.9771 31.8 22.0686 32.7 22.2514L31.7 20.4229L35.8 12.8343H43.1L36.9 24.08C37.4 24.4457 37.9 24.8114 38.4 25.1771C38.7 25.4514 39 25.7257 39.2 26C39.4 25.5429 39.7 25.1771 39.9 24.72L48 10H34L30 17.3143L26 10H12C14.9 15.3029 17.8 20.6057 20.7 26C21 25.7257 21.3 25.4514 21.6 25.1771V25.0857Z" fill={icon_color}/>
                </svg>
            </div>;
        case "community":
            return <div className='awards glitch_no_blur'>
                <svg width="48" height="48" fill="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                    <path class="cls-1" d="M38.3,24.1c-.5-1.5-1.3-2.9-2.3-4.2-.2-.3-.4-.6-.7-.8l-.2-.2c-.2-.2-.4-.4-.6-.6h0c-.9-.9-1.8-1.7-2.8-2.3-.6-.4-1.3-.7-1.9-1-.2,0-.3,0-.5-.2-.2,0-.5-.2-.7-.3h-.3c-1.4-.4-2.8-.6-4.3-.6h-.4c-1.3,0-2.6.3-3.9.6-1.2.4-2.4.9-3.5,1.5-1,.6-2,1.3-2.8,2.2-.2.2-.4.4-.6.7l-.2.2c-.2.3-.5.5-.7.8-1,1.3-1.7,2.7-2.3,4.2-.5,1.5-.8,3.2-.8,4.9s.3,3.4.8,4.9,1.3,2.9,2.3,4.2c1,1.4,2.3,2.5,3.7,3.5,1.2.8,2.5,1.4,3.9,1.8,1.4.4,2.9.7,4.4.7s3-.2,4.4-.7c1.4-.4,2.7-1.1,3.9-1.8.3-.2.7-.5,1-.7,1-.8,1.9-1.7,2.7-2.7,1-1.3,1.7-2.7,2.3-4.2.5-1.5.8-3.2.8-4.9s-.3-3.4-.8-4.9h0ZM32.4,37.4c-2.3,2.3-5.1,3.5-8.5,3.5s-6.1-1.2-8.4-3.5c-2.3-2.3-3.5-5.1-3.5-8.5s1.2-6.1,3.5-8.4c2.3-2.3,5.1-3.5,8.5-3.5s6.1,1.2,8.4,3.5,3.5,5.1,3.5,8.5-1.2,6.1-3.5,8.4Z" fill={icon_color}/>
                    <path class="cls-2" d="M15.5,20.5c.5-.5.9-.9,1.4-1.2l-6.2-12.3h7.4l5,10h.9c.9,0,1.8,0,2.6.3l-1-2,4.1-8.3h7.4l-6.2,12.2c.5.4,1,.8,1.5,1.3s.6.7.9,1c.3-.5.5-.9.7-1.4l8-16.1h-14l-4,8-4-8H6c2.9,5.8,5.8,11.6,8.7,17.4.3-.3.5-.6.8-.9Z" fill={icon_color}/>
                    <circle class="cls-2" cx="24" cy="26.7" r="2.4" fill={icon_color}/>
                    <path class="cls-2" d="M25.6,29.7h-3.3c-1.1,0-2.1.9-2.1,2.1v3.3c1.1.4,2.4.7,3.7.7s2.6-.3,3.7-.7v-3.3c0-1.1-.9-2.1-2.1-2.1h0Z" fill={icon_color}/>
                    <circle class="cls-2" cx="17.9" cy="25.4" r="2.4" fill={icon_color}/>
                    <circle class="cls-2" cx="29.8" cy="25.3" r="2.4" fill={icon_color}/>
                    <path class="cls-2" d="M18.7,31.8c0-1.4.8-2.6,2-3.2-.3-.2-.7-.4-1.1-.4h-3.2c-1.1,0-2,.9-2,2v3.2c1.1.4,2.3.7,3.6.7h.7v-2.3Z" fill={icon_color}/>
                    <path class="cls-2" d="M31.4,28.2h-3.2c-.5,0-.8.1-1.1.4,1,.5,1.8,1.5,2,2.7,0,.2,0,.4,0,.6v2.3h.7c1.3,0,2.5-.2,3.6-.7v-3.2c0-1.1-.9-2-2-2Z" fill={icon_color}/>
                </svg>
            </div>;
        case "trophy":
            return <div className='awards glitch_no_blur'>
                <svg width="24px" height="24px" viewBox="0 -960 960 960" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M280-120v-80h160v-124q-49-11-87.5-41.5T296-442q-75-9-125.5-65.5T120-640v-40q0-33 23.5-56.5T200-760h80v-80h400v80h80q33 0 56.5 23.5T840-680v40q0 76-50.5 132.5T664-442q-18 46-56.5 76.5T520-324v124h160v80H280Zm0-408v-152h-80v40q0 38 22 68.5t58 43.5Zm200 128q50 0 85-35t35-85v-240H360v240q0 50 35 85t85 35Zm200-128q36-13 58-43.5t22-68.5v-40h-80v152Zm-200-52Z" fill={icon_color}/>
                </svg>
            </div>;
        default:
            return <div className='awards'>{award}</div>;
    }
};

export default Award;